import { FC } from 'react'

import { styled } from '@mui/material'
import {
  DesktopDatePicker as MuiDatePicker,
  LocalizationProvider,
  DatePickerProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ja from 'date-fns/locale/ja'

// NOTE: styledでラップすると、genericsが効かなくなるので型アサーションしている（https://qiita.com/kthksgy/items/0154e616f8fd49d022ff）
const StyleDatePicker = styled(MuiDatePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    ...theme.shape,
    padding: '13px 16px',
  },
  '& .MuiButtonBase-root': {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
    '& fieldset': {
      top: '0',
    },
  },
  label: {
    position: 'relative',
    color: '#000',
    marginBottom: '8px',
    transform: 'inherit',

    '&.Mui-focused, &.Mui-error': {
      color: 'inherit',
    },
  },
  fieldset: {
    top: 0,
  },
  legend: {
    span: {
      display: 'none',
    },
  },
})) as typeof MuiDatePicker

export const DatePicker: FC<DatePickerProps<Date | string>> = ({
  defaultValue,
  value,
  format,
  ...rest
}) => {
  const convertedDefaultValue = typeof value === 'string' ? new Date(value) : value
  const convertedValue = typeof defaultValue === 'string' ? new Date(defaultValue) : defaultValue
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
    >
      <StyleDatePicker
        {...rest}
        value={convertedValue}
        defaultValue={convertedDefaultValue}
        format={format}
      />
    </LocalizationProvider>
  )
}
