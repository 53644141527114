import React, { forwardRef, useEffect, useState } from "react";

import {
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup as MuiRadioGroup,
  Stack,
  Box,
  Grid,
  RadioGroupProps,
} from "@mui/material";
import { GetShippingAddressesModelDTO } from "api-client";
import { useNavigate } from "react-router";

import { Message } from "../../atoms/Message/index";
import { EditIcon } from "../Icon/EditIcon";

export type TextInputProps = {
  groupLabel: string;
  options: {
    label: string;
    value: string | number;
  }[];
  required: boolean;
  error: boolean;
  errorText?: string;
  disabled?: boolean;
  sx?: any;
} & RadioGroupProps;

export type Props = {
  groupLabel?: string;
  options: {
    label: string;
    value: string;
  }[];
  required?: boolean;
  disabled?: boolean;
  sx?: any;
  setSelectedValueFunction: any;
  defaultValue?: string;
  navigatePath?: string;
  localShippingAddressId?: string | null;
  isShippingAddressIdByPatient?: boolean;
  isEditFromMyPage?: boolean;
  rowShippingAddresses?: Array<GetShippingAddressesModelDTO>
  setSelectedFunction?: any;
  shippingInfo?: {
    shippingPostalCode: string;
    shippingPrefecture: string;
    shippingAddress: string;
  };
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RadioGroup = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { groupLabel, options, required, error, errorText, disabled, sx, defaultValue, name, onChange, value, ...field },
    ref
  ) => {
    return (
      <FormControl>
        <Stack spacing={1}>
          {groupLabel &&
            <Stack direction="row" spacing={0.5}>
              <Typography variant="bold14">{groupLabel}</Typography>
              {required && <Box color="alert.100">*</Box>}
            </Stack>}
          {error && errorText && <Message message={errorText} />}
          <MuiRadioGroup row ref={ref} defaultValue={defaultValue} name={name} onChange={onChange} value={value} {...field}>
            <Grid container spacing={1} direction="row">
              {options.map(({ label, value }) => (
                <Grid item key={label} sx={sx}>
                  <Box
                    paddingY={1}
                    paddingLeft={2}
                    border="1px solid"
                    borderRadius="6px"
                    borderColor="dark.20"
                  >
                    <FormControlLabel
                      value={value}
                      control={<Radio disabled={disabled} />}
                      label={label}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </MuiRadioGroup>
        </Stack>
      </FormControl>
    );
  }
);

export const RadioGroupShippingAddress: React.FC<Props> = ({
  groupLabel,
  options,
  required,
  disabled,
  sx,
  setSelectedValueFunction,
  defaultValue,
  navigatePath,
  localShippingAddressId,
  isShippingAddressIdByPatient,
  isEditFromMyPage,
  rowShippingAddresses,
  setSelectedFunction,
  shippingInfo,
  setIsEdit,
}) => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [sortedOptions, setSortedOptions] = useState<Array<{ label: string, value: string }>>([]);

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    setSelectedValueFunction(event.target.value);
    setIsEdit && setIsEdit(true);
  };

  const sortByDefaultValue = (list: Array<{ label: string, value: string }>, targetValue: string) => {
    const index = list.findIndex(item => item.value === targetValue);
    if (index !== -1) {
      const targetObject = list[index];
      // targetElementが見つかった場合、それを先頭に移動させる
      list.splice(index, 1); // targetElementを削除
      list.unshift(targetObject); // targetElementを先頭に追加
    }
    return list;
  }

  useEffect(() => {
    setSortedOptions(options);

    // このコンポーネントは、新規予約時とマイページからのお届け先住所登録編集時に使用される前提で実装されている
    // 診察詳細画面からお届け先住所を表示する際には、診察レコードに紐づいているお届け先住所となる必要がある
    // 既存の処理を使い回すことができないため、それにあたるお届け先住所の値を、明示的に親コンポーネントから渡している（shippingInfo）
    // そのため、shippingInfoの値があるかどうかを条件分岐の処理で判断している
    if (shippingInfo && rowShippingAddresses && rowShippingAddresses.length > 0) {
      // shippingInfoと一致する住所を検索
      const matchingAddress = rowShippingAddresses.find((item) => {
        return (
          item.postalCode === shippingInfo.shippingPostalCode &&
          item.prefecture === shippingInfo.shippingPrefecture &&
          item.address === shippingInfo.shippingAddress
        );
      });

      if (matchingAddress) {
        setSelectedValue(matchingAddress.id);
        setSelectedValueFunction(matchingAddress.id);

        setSortedOptions((prevOptions) =>
          sortByDefaultValue(prevOptions, matchingAddress.id!)
        );
      }
    } else if (localShippingAddressId && !isShippingAddressIdByPatient) {
      setSelectedValue(localShippingAddressId);
      setSortedOptions(options);
    } else if (!localShippingAddressId && isShippingAddressIdByPatient) {
      setSelectedValue(defaultValue);
      setSortedOptions(sortByDefaultValue(options, defaultValue!));
    } else if (localShippingAddressId && isShippingAddressIdByPatient) {
      setSelectedValue(localShippingAddressId);
      setSortedOptions(sortByDefaultValue(options, defaultValue!));
    } else {
      setSortedOptions(options);
    }
    // eslint-disable-next-line
  }, [options]);

  return (
    <FormControl>
      <Stack spacing={1}>
        <Stack spacing={0.5}>
          <Typography variant="bold14">{groupLabel}</Typography>
          {required && <Box color="alert.100">*</Box>}
        </Stack>
        <MuiRadioGroup
          value={selectedValue || defaultValue}
          defaultValue={defaultValue}
          //defaultChecked={true}
          onChange={handleChange}
        >
          <Grid container spacing={1}>
            {sortedOptions.map(({ label, value }) => (
              <Grid item key={label} sx={sx}>
                <Box
                  display={'flex'}
                  width={"calc(100vw - 58px)"}
                  maxWidth="582px"
                  //minWidth={'95%'}
                  paddingY={1}
                  paddingLeft={2}
                  border="1px solid"
                  borderRadius="6px"
                  borderColor={
                    selectedValue === value ?
                      '#355CD7' : 'dark.20'
                  }
                  justifyContent="space-between"
                  overflow={'hidden'}
                >
                  <FormControlLabel
                    value={value}
                    control={<Radio disabled={disabled} />}
                    label={label}
                  />
                  <Box
                    sx={{ size: '0px', display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                      localStorage.shippingAddressId = value;
                      if (isEditFromMyPage) {
                        selectedValue === value ?
                          setSelectedFunction(
                            rowShippingAddresses?.find(
                              (item) => item.id === selectedValue)
                          ) :
                          null;
                      } else {
                        setSelectedValueFunction(selectedValue);
                        selectedValue === value ?
                          navigate(navigatePath + `&shippingAddressId=${selectedValue}`) :
                          null;
                      }
                    }}
                  >
                    <EditIcon />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </MuiRadioGroup>
        {/*error && errorText && <Message message={errorText} />*/}
      </Stack>
    </FormControl>
  );
};
