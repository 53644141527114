import { ReactNode } from "react";

import { Stack } from "@mui/material";
import { mediaQuery, useMediaQuery } from "hooks/useMediaQuery";

export type Props = {
  children: ReactNode;
};

const AuthenticationLayout = ({ children }: Props) => {
  const isPC = useMediaQuery(mediaQuery.pc);

  return (
    <Stack
      sx={{
        width: "full",
        maxWidth: "600px",
        minHeight: "100vh",
        paddingTop: "20px",
        paddingX: "20px",
        margin: "0 auto",
        boxShadow: isPC ? "0px 4px 47px 0px #0000001A" : null,
      }}
    >
      {children}
    </Stack>
  );
};

export default AuthenticationLayout;
