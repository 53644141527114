import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import {
  StripeFieldType,
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "./StripeTextFields";

import type { Stripe } from "@stripe/stripe-js";

type Props = {
  handleSubmit: (e: any) => void;
  stripe: Stripe | null;
  disabled: boolean;
  loading: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Presenter: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, stripe, disabled, loading, setIsEdit } = props;
  const [localDisabled, setLocalDisabled] = useState(true);
  useEffect(() => {
    setLocalDisabled(!stripe);
  }, [stripe]);

  useEffect(() => {
    setLocalDisabled(disabled);
  }, [disabled]);

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <StripeTextFieldNumber
          type={StripeFieldType.CardNo}
          setIsEdit={setIsEdit}
        />
        <Stack direction="row" spacing="14px">
          <StripeTextFieldExpiry
            type={StripeFieldType.Expiry}
            setIsEdit={setIsEdit}
          />
          <StripeTextFieldCVC
            type={StripeFieldType.SecurityCode}
            setIsEdit={setIsEdit}
          />
        </Stack>
      </Stack>
      <Box marginTop="30vh">
        <Button
          variant="onboarding"
          color="primary"
          type="submit"
          sx={{ width: "100%", borderRadius: "10px", height: "48px" }}
          disabled={localDisabled}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {setIsEdit !== undefined ? t("common.button.save") : t("common.button.next")}
        </Button>
      </Box>
    </form>
  );
};
